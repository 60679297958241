import React, { Fragment, useState } from "react";
import { Accordion, AccordionHeader, AccordionBody, } from "@material-tailwind/react";


function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`${id === open ? "rotate-180" : ""
                } h-7 w-7 transition-transform`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="#fbbd69"
            strokeWidth={2}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
        </svg>
    );
}


const CommonQuestion = () => {
    const [open, setOpen] = useState(0);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    return (
        <>
            <section className="tv-section8">
                <div className="container mx-auto px-4">
                    <h2 className="h2 text-secondary text-center">Some common setup questions</h2>
                    <div className="max-width1000">
                        <Fragment>
                            <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(1)}>
                                    What should I do if I do not see the three blue lights?
                                </AccordionHeader>
                                <AccordionBody>
                                    If you don't see all three lights lit, confirm your phone box is plugged into a live internet connection, and try unplugging and replugging the phone box. If this doesn't work please shoot us a message or a call and we can assist.                                
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(2)}>
                                    What are all the features included?
                                </AccordionHeader>
                                <AccordionBody>
                                    This whole list is free and included:

                                    <table>
                                    <tr>
                                        <th>Title</th>
                                        <th>Description</th>

                                    </tr>
                                    <tr>
                                        <td>Voicemail</td>
                                        <td>A voicemail box was assigned to you with your service. In the Voice Portal, you can upload your voicemail greetings and personal name recordings, view and manage any saved messages, and set up voicemail forwarding to email.</td>
                                    </tr>
                                    <tr>
                                        <td>Caller ID </td>
                                        <td>The name sent with caller ID, sometimes referred to as CNAM, is a setting on your phone number. </td>
                                    </tr>
                                    <tr>
                                        <td>Call History</td>
                                        <td>Call History holds the records of all calls made and received on the account.</td>
                                    </tr>
                                    <tr>
                                        <td>Do Not Disturb </td>
                                        <td>When you don’t want your phone to ring, activate Do Not Disturb to send all incoming calls directly to your voicemail box.</td>
                                    </tr>
                                    <tr>
                                        <td>Last Call Return</td>
                                        <td>Three-way calling lets you bring two parties into the same call so all may participate in the same conversation.</td>
                                    </tr>
                                    <tr>
                                        <td>Voicemail Local Access</td>
                                        <td>Your voicemail box can be accessed in the Voice Portal, remotely, or by dialing a star code from a phone connected to the account.</td>
                                    </tr>
                                    <tr>
                                        <td>Three-way Calling</td>
                                        <td>Three-Way Calling lets you talk with two people in two different locations, at the same time, or talk privately with one party while the other holds.</td>
                                    </tr>
                                    <tr>
                                        <td>Call Handling</td>
                                        <td>Call Handling determines how your incoming calls will be routed under different circumstances.</td>
                                    </tr>
                                    <tr>
                                        <td>Call Screening</td>
                                        <td>You have control to ensure you receive important calls. You can limit the callers that ring your phone, filter callers, or forward important callers to another number.</td>
                                    </tr>
                                    <tr>
                                        <td>Anonymous Callers</td>
                                        <td>Calls from anonymous callers (those without caller ID) can waste your time and distract you from other calls you want to take. Block them from even ringing your phone.</td>
                                    </tr>
                                    <tr>
                                        <td>Custom Callers </td>
                                        <td>Use this feature to control who can call your phone and what happens when they do. These settings can be managed in the Voice Portal or by star code.</td>
                                    </tr>
                                    <tr>
                                        <td>Robocall Blocking</td>
                                        <td>Block the telephone numbers of known telemarketers and robocallers.</td>
                                    </tr>
                                    <tr>
                                        <td>Sim Ring</td>
                                        <td>Enables a call to ring to multiple lines and/or phone numbers simultaneously.</td>
                                    </tr>
                                    <tr>
                                        <td>Call Trace</td>
                                        <td>Call Trace allows you to mark a harassing or threatening phone call in your Call History.</td>
                                    </tr>
                                    <tr>
                                        <td>Call Forwarding</td>
                                        <td>When you aren’t available to answer calls on your phone, you can forward your calls somewhere else. Call Forwarding can be set up in the voice portal or by star codes. In the Voice Portal, you can set up a schedule to forward calls during certain hours, like your lunch break.</td>
                                    </tr>
                                    <tr>
                                        <td>Remote Access Forwarding</td>
                                        <td>When you’re away from your phone but still want to receive calls, you don’t need to get back to your phone to set things up. Unconditional call forwarding, or forwarding of all calls, is available remotely using the voicemail system, so it will continue to function even if there is a power outage, internet outage, or device failure.</td>
                                    </tr>
                                    <tr>
                                        <td>Call Waiting</td>
                                        <td> With Call Waiting, you can see who is calling, even if you’re on another call (as long as caller ID is supported on your phone). If a second call comes in while you’re on a call, you’ll hear the Call Waiting tone. To answer that call, you can choose to place your current call on hold and pick up the incoming call. Additionally, you can temporarily or completely enable or disable Call Waiting from your phone or in the Voice Portal.</td>
                                    </tr>
                                    <tr>
                                        <td>Collect Calls</td>
                                        <td> Collect calls are currently not offered.</td>
                                    </tr>
                                    </table>   
                                    </AccordionBody>                        
                                 </Accordion>
                            <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(3)}>
                                        What are the star codes for voice mail, return missed call and others?
                                </AccordionHeader>
                                <AccordionBody>
                                <p>You can find the full list here: <a href="https://purplecowinternet.com/home-phone/star-codes/" style={{color: "purple"}}>STAR CODES</a></p>
                                </AccordionBody>
                            </Accordion>
                        </Fragment>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CommonQuestion

import React from "react";
import LayoutNew from "../../components/LayoutNew";
import { Helmet } from "react-helmet";
import HaveQuestion from "../new-pages/common/HaveQuestion";
import MessageModal from "../new-pages/common/MessageModal";
import bubbleIcon from "../../../static/images/speech-bubble.png";
import CommonQuestionWhenSettingUpYourHomePhone from "../new-pages/common/CommonQuestionWhenSettingUpYourHomePhone";
 

const PhoneSetupPage = () => {

    const [messagePopup, setMessagePopup] = React.useState(false);  


    return (
        <LayoutNew>
            <Helmet>
                <title>Phone – Purple Cow Internet 💜🐄</title>
            </Helmet>

            <section className="tv-section1">
            <div className="container mx-auto px-4">
                    <h2 className="h2 text-secondary text-center">How to setup your home phone</h2>
                </div>
            <div>
            <iframe width="1120" height="630" src="https://www.youtube.com/embed/6BV6SunygsA?si=Ig_nXjPAITxfwB8k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            </section>
            <section className="tv-section1 max-width1000">
                <h2>Step 1: Plug your phone box into your internet modem</h2>
                <br></br>
                    <p className="container mx-auto px-4 text-secondary text-center">
                    Once you receive notification that your new number is assigned or your current one has been successfully ported, you can unbox your package. Take the blue cord and plug one end into the back of the modem and the other into the blue port on the phone box. While you're at it, plug in the power as well.                    </p>
            </section>
            <section className="tv-section1 max-width1000">
                <h2>Step 2: Plug your handset into the phone box</h2>
                <br></br>
                    <p className="container mx-auto px-4 text-secondary text-center">
                    Now it's time to plug in your phone. You have two options: First, plug your phone handset directly into the back of the phone box. Secondly, if you have an extra phone cord, you can plug the phone box into your wall jack, enabling all other phone ports in your home.                    </p>
            </section>
            <section className="tv-section1 max-width1000">
                <h2>Step 3: Use your new Home Phone</h2>
                <br></br>
                    <p className="container mx-auto px-4 text-secondary text-center">
                    Give your phone box about 5 minutes to set up; you should see all three blue lights lit. If you don't see all lights, confirm your phone box is plugged into a live internet connection, and try unplugging and replugging the phone box.                    </p>
            </section>

            <CommonQuestionWhenSettingUpYourHomePhone />

            <HaveQuestion closepopup={setMessagePopup}/>
            <div className="contact-us-parent">
                <div className="contact-click-box" onClick={() => setMessagePopup(true)}>
                    <img src={bubbleIcon} alt="bubble" className="bubble-icon" />
                </div>
            </div>
            {messagePopup ? (<MessageModal closepopup={setMessagePopup}></MessageModal>) : null}
        </LayoutNew >
    )
}

export default PhoneSetupPage